import { type MissingKeys } from '../en'

const de = {
  general: {
    cancel: 'Abbrechen',
    save: 'Speichern',
    restore: 'Wiederherstellen',
    delete: 'Löschen',
    deleteNone: 'Keine',
    deleteMore: amount => `... und ${amount} weitere`,
    edit: 'Bearbeiten',
    add: 'Hinzufügen',
    create: 'Erstellen',
    show: 'Anzeigen',
    hide: 'Verbergen',
    showAll: 'Alle anzeigen',
    hideAll: 'Alle verbergen',
    showDetails: 'Details anzeigen',
    hideDetails: 'Details verbergen',
    showMore: 'Mehr anzeigen',
    showLess: 'Weniger anzeigen',
    showPassword: 'Passwort anzeigen',
    hidePassword: 'Passwort verbergen',
    copy: 'Kopieren',
    copied: 'Kopiert',
    next: 'Weiter',
    previous: 'Zurück',
    available: 'Verfügbar',
    selected: 'Ausgewählt',
    selection: 'Auswahl',
    enabled: 'Aktiviert',
    disabled: 'Deaktiviert',
    noItems: 'Keine Elemente vorhanden',
    profileMenuBtn: 'Öffne Einstellungen',
    createNew: 'Neu erstellen',
    useExisting: 'Vorhandenes verwenden',
    close: 'Schließen',
    search: 'Suche',
    current: 'Aktuell',
    start: 'Ausführen',
    stop: 'Anhalten',
    information: 'Information',
    warning: 'Warnung',
    error: 'Fehler',
    default: 'Default',
    sortAscending: 'Aufsteigend sortieren',
    sortDescending: 'Absteigend sortieren',
    notAvailableInLocalMode: 'Nicht verfügbar im lokalen Modus',
    timeAgo: info =>
      info.days
        ? `${info.days} Tag${info.days !== 1 ? 'e' : ''}`
        : info.hours
        ? `${info.hours} Stunde${info.hours !== 1 ? 'n' : ''}`
        : info.minutes !== undefined
        ? `${info.minutes} Minute${info.minutes !== 1 ? 'n' : ''}`
        : '',
    cron: {
      wizard: 'Wizard',
      repeatEvery: 'Wiederhole alle',
      amount: 'Anzahl',
      mode: 'Modus',
      modes: {
        minutes: 'Minuten',
        hours: 'Stunden',
        days: 'Tage (im Monat)',
        weeks: 'Wochen',
        months: 'Monate (im Jahr)',
        years: 'Jahre',
      },
      time: 'Zeit',
      hours: 'Stunden',
      minutes: 'Minuten',
      onWeekdays: 'An Wochentagen',
      onMonths: 'In Monaten',
      runs: 'Läuft',
      nextRuns: amount => `Nächste ${amount} Läufe`,
      invalid: 'Ungültiger Cron Ausdruck',
      showCalendar: 'Kalender anzeigen',
      hideCalendar: 'Kalender verbergen',
    },
    weekdays: {
      monday: 'Montag',
      tuesday: 'Dienstag',
      wednesday: 'Mittwoch',
      thursday: 'Donnerstag',
      friday: 'Freitag',
      saturday: 'Samstag',
      sunday: 'Sonntag',
    },
    weekdaysShort: {
      monday: 'Mo',
      tuesday: 'Di',
      wednesday: 'Mi',
      thursday: 'Do',
      friday: 'Fr',
      saturday: 'Sa',
      sunday: 'So',
    },
    months: {
      january: 'Januar',
      february: 'Februar',
      march: 'März',
      april: 'April',
      may: 'Mai',
      june: 'Juni',
      july: 'Juli',
      august: 'August',
      september: 'September',
      october: 'Oktober',
      november: 'November',
      december: 'Dezember',
    },
    groups: {
      admin: 'Administrator',
      projectManager: 'Projektmanager',
      dev: 'Entwickler',
    },
    errors: {
      sessionRenew: 'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.',
      login: 'Beim Einloggen ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
      add: 'Beim Hinzufügen des Elements ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
      update:
        'Beim Aktualisieren des Elements ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
      error: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es in ein paar Minuten erneut.',
      retry: 'Erneut versuchen',
      notFound: 'Seite nicht gefunden',
      fetch: 'Beim Abrufen der Daten ist ein Fehler aufgetreten.',
      retryFetch: 'Erneuter Versuch, Daten abzurufen...',
      connection: 'Verbindung zum Server fehlgeschlagen',

      input: {
        required: 'Dieses Feld ist erforderlich',
        unique: 'Dieses Feld muss eindeutig sein',
        number: 'Dieses Feld muss eine Zahl sein',
        positive: 'Dieses Feld muss eine positive Zahl sein',
        min: min => `Dieses Feld muss mindestens ${min} Zeichen lang sein`,
        max: max => `Dieses Feld darf maximal ${max} Zeichen lang sein`,
        minAmount: (min: number) => `Dieses Feld muss mindestens ${min} sein`,
        maxAmount: (max: number) => `Dieses Feld darf maximal ${max} sein`,
        minAdded: (min: number, name: string) => `Fügen Sie mindestens ${min} ${name} hinzu`,
        maxAdded: (min: number, name: string) => `Fügen Sie höchstens ${min} ${name} hinzu`,
        minSelection: (min: number) =>
          `Es ${min === 1 ? 'muss' : 'müssen'} mindestens ${min} Element${
            min === 1 ? '' : 'e'
          } ausgewählt werden`,
        email: 'Dieses Feld muss eine gültige E-Mail Adresse sein',
        url: 'Dieses Feld muss eine gültige URL sein',
        projectName:
          'Dieses Feld darf nur die Zeichen a-z, 0-9 und - enthalten und muss mit einem Buchstaben beginnen',
        dnsSubdomainName:
          'Dieses Feld darf nur die Zeichen a-z, 0-9, - und . enthalten und muss mit einem Buchstaben oder einer Zahl beginnen und enden',
        ip: 'Dieses Feld muss eine gültige IP Adresse sein',
        ipWithMask: 'Dieses Feld muss eine gültige IP Adresse mit Subnetzmaske sein',
        domainName: 'Dieses Feld muss eine gültige Domain sein',
        projectNameUnique: 'Dieser Projektname ist bereits vergeben',
      },
    },
  },
  settings: {
    title: 'Einstellungen',
    language: 'Sprache',
    theme: 'Farben',
    theme_light: 'Hell',
    theme_dark: 'Dunkel',
    theme_system: 'System',
    logout: 'Ausloggen',
    enterAdminPanel: 'Admin Panel betreten',
    leaveAdminPanel: 'Admin Panel verlassen',
  },
  navigation: {
    skipNavigation: 'Navigation überspringen',
    dashboard: 'Dashboard',
    projects: 'Projekte',
    projectQuotaRequests: 'Kontingentanfragen',
    computing: 'Computing',
    virtualMachines: 'Virtuelle Maschinen',
    clusters: {
      title: 'Cluster',
      details: 'Details',
      configurations: 'Konfigurationen',
      secrets: 'Geheimnisse',
      apps: 'Apps',
      appStore: 'App Store',
      installedApps: 'Installierte Apps',
    },
    containers: 'Container',
    storage: 'Speicher',
    disks: 'Datenträger',
    images: 'Abbilder',
    diskSnapshots: 'Momentaufnahmen',
    backupPolicies: 'Sicherungsrichtlinien',
    networks: 'Netzwerke',
    termsOfService: 'AGB',
    privacyPolicy: 'Datenschutz',
    imprint: 'Impressum',
    version: version => `Version: ${version}`,
  },
  forms: {
    basic: 'Basis',

    metadata: {
      title: 'Metadaten',

      projectName: 'Projekt',
      namespace: 'Namespace',
      name: 'Name',
      displayName: 'Anzeigename',
      description: 'Beschreibung',
      createdAt: 'Erstellt',
      updatedAt: 'Aktualisiert',
    },
    project: {
      title: 'Projekt',
      titlePlural: 'Projekte',
      create: 'Projekt hinzufügen',
      edit: 'Projekt bearbeiten',

      groupPrefix: 'Gruppenpräfix',
      costCenter: 'Kostenstelle',
    },
    projectQuotaRequest: {
      increase: 'Mehr anfragen',
      request: 'Kontingent anfragen',
      description:
        'Jedem Projekt stehen begrenzte Ressourcen zur Verfügung. Sie können eine Aufstockung ihrer Kontingente anfragen.',
      newMax: 'Gewünschtes Maximum',
      currentQuota: (max: number) => `Aktuelles Kontingent: ${max}`,
      maxCpuCores: 'CPU Kerne anfragen',
      maxMemoryGib: 'Arbeitsspeicher (GiB) anfragen',
      maxDiskSizeGib: 'Datenträgergröße (GiB) anfragen',
      maxDiskIops: 'Datenträger IOPS anfragen',
      maxVirtualMachineCount: 'Kontingent an virtuellen Maschinen anfragen',
      maxClusterCount: 'Clusterkontingent anfragen',
      maxContainerCount: 'Containerkontingent anfragen',
      maxDiskCount: 'Datenträgerkontingent anfragen',
      maxDiskCountPerVirtualMachine: 'Datenträgerkontingent pro virtueller Maschine anfragen',
      maxNetworkCount: 'Netzwerkkontingent anfragen',
      maxNetworkCountPerVirtualMachine: 'Netzwerkkontingent pro virtueller Maschine anfragen',
      maxNetworkCountPerCluster: 'Netzwerkkontingent pro Cluster anfragen',
      maxNetworkCountPerContainer: 'Netzwerkkontingent pro Container anfragen',
      sentNotification: 'Die Kontingentanfrage wurde erfolgreich versendet.',
      alert: {
        maxCpuCores:
          'Die Auswahl der CPU Kerne ist eingeschränkt, da im Projekt nicht mehr Ressourcen zur Verfügung stehen.',
        maxMemoryGib:
          'Die Auswahl des Arbeitsspeichers ist eingeschränkt, da im Projekt nicht mehr Ressourcen zur Verfügung stehen.',
        maxDiskSizeGib:
          'Die Auswahl der Datenträgergröße ist eingeschränkt, da im Projekt nicht mehr Ressourcen zur Verfügung stehen.',
        maxDiskIops:
          'Die Auswahl der Datenträger IOPS ist eingeschränkt, da im Projekt nicht mehr Ressourcen zur Verfügung stehen.',
        maxVirtualMachineCount:
          'Das Erstellen dieser virtuellen Machine ist nicht möglich, da im Projekt keine weiteren Ressourcen zur Verfügung stehen.',
        maxClusterCount:
          'Das Erstellen dieses Clusters ist nicht möglich, da im Projekt keine weiteren Ressourcen zur Verfügung stehen.',
        maxContainerCount:
          'Das Erstellen dieses Containers ist nicht möglich, da im Projekt keine weiteren Ressourcen zur Verfügung stehen.',
        maxDiskCount:
          'Das Erstellen dieses Datenträgers ist nicht möglich, da im Projekt keine weiteren Ressourcen zur Verfügung stehen.',
        maxDiskCountPerVirtualMachine:
          'Das Hinzufügen eines weiteren Datenträgers ist nicht möglich, da die Anzahl der Datenträger pro virtueller Maschine im Projekt begrenzt ist.',
        maxNetworkCount:
          'Das Erstellen dieses Netzwerkes ist nicht möglich, da im Projekt keine weiteren Ressourcen zur Verfügung stehen.',
        maxNetworkCountPerVirtualMachine:
          'Das Hinzufügen eines weiteren Netzwerkes ist nicht möglich, da die Anzahl der Netzwerke pro virtueller Maschine im Projekt begrenzt ist.',
        maxNetworkCountPerCluster:
          'Das Hinzufügen eines weiteren Netzwerkes ist nicht möglich, da die Anzahl der Netzwerke pro Cluster im Projekt begrenzt ist.',
        maxNetworkCountPerContainer:
          'Das Hinzufügen eines weiteren Netzwerkes ist nicht möglich, da die Anzahl der Netzwerke pro Container im Projekt begrenzt ist.',
      },
    },
    cluster: {
      title: 'Cluster',
      titlePlural: 'Cluster',
      create: 'Cluster hinzufügen',
      edit: 'Cluster bearbeiten',

      kubernetesVersion: 'Kubernetes Version',
      networkMounts: 'Netzwerk Mounts',
      runStatus: 'Status',
      runStatusValues: {
        running: 'Läuft',
        pending: 'Wartet',
        stopped: 'Angehalten',
      },
      controlPlaneUrl: 'Control Plane URL',
    },
    app: {
      title: 'App',
      titlePlural: 'Apps',
      install: 'Installieren',
      uninstall: 'Deinstallieren',

      configure: (name: string) => `${name} App konfigurieren`,
      parameters: 'Parameter',

      version: 'Version',
      docsUrl: 'Dokumentation',
      repoUrl: 'Repository',
      category: {
        title: 'Kategorie',
        database: 'Datenbank',
        webserver: 'Webserver',
        monitoring: 'Monitoring',
      },
      categoryPlural: {
        database: 'Datenbanken',
        webserver: 'Webserver',
        monitoring: 'Monitoring',
      },
    },
    secret: {
      title: 'Geheimnis',
      titlePlural: 'Geheimnisse',
      create: 'Geheimnis hinzufügen',
      edit: 'Geheimnis bearbeiten',

      dockerConfigData: {
        registry: 'Registry',
        username: 'Benutzername',
        password: 'Passwort',
      },
      type: {
        title: 'Typ',
        opaque: 'Benutzerdefiniert',
        dockerConfig: 'Docker Config',
        other: 'Anderes',
      },
      data: {
        title: 'Daten',
        key: 'Schlüssel',
        value: 'Wert',
        noData: 'Noch kein Wert hinzugefügt',
        create: 'Wert hinzufügen',
      },
    },
    configuration: {
      title: 'Konfiguration',
      titlePlural: 'Konfigurationen',
      create: 'Konfiguration hinzufügen',
      edit: 'Konfiguration bearbeiten',

      data: {
        title: 'Daten',
        key: 'Schlüssel',
        value: 'Wert',
        noData: 'Noch kein Wert hinzugefügt',
        create: 'Wert hinzufügen',
      },
    },
    container: {
      title: 'Container',
      titlePlural: 'Container',
      create: 'Container hinzufügen',
      edit: 'Container bearbeiten',
      runStatus: 'Status',
      runStatusValues: {
        running: 'Läuft',
        pending: 'Wartet',
        stopped: 'Angehalten',
      },
      resources: {
        title: 'Ressourcen',
        cpuCores: 'CPU Kerne',
        memoryGib: 'Arbeitsspeicher (GiB)',
      },
      scaling: {
        title: 'Skalierung',
        replicas: 'Replikate',
        minReplicas: 'Minimale Replikate',
        maxReplicas: 'Maximale Replikate',
        autoscaling: {
          title: 'Skalierung',
          automatic: 'Automatisch',
          manual: 'Manuell',
        },
        autoscaleCpuUsage: 'CPU Auslastung in %',
        autoscaleMemoryUsage: 'Arbeitsspeicher Auslastung in %',
      },
      connectivity: {
        title: 'Konnektivität',
        containerPort: 'Container Port',
        ingress: {
          title: 'Ingress',
          enabled: 'Ingress aktiviert',
          disabled: 'Ingress deaktiviert',
          info: 'Ingress ermöglicht den externen Zugriff auf den Container.',
        },
        externalDomain: 'Externe Domain',
      },
      gpu: {
        title: 'GPU',
        enabled: 'GPU aktiviert',
        disabled: 'GPU deaktiviert',
        info: 'Die Verwendung von GPU-Ressourcen im Cluster steigert die Leistung für maschinelles Lernen und rechenintensive Aufgaben.',
      },
      source: {
        title: 'Quelle',
        type: 'Quelltyp',
        registry: 'Registry',
        registryName: 'Name',
        registryTag: 'Tag',
        registryUrl: 'URL',
        registryUsername: 'Benutzername',
        registryPassword: 'Passwort',
        git: 'Git Repository',
        gitUrl: 'Git URL',
        gitSsh: 'SSH',
        gitSshPublicKey: 'Public Key',
        gitHttps: 'HTTPS',
        gitHttpsUsername: 'Benutzername',
        gitHttpsPassword: 'Passwort',
      },
    },
    virtualMachine: {
      title: 'Virtuelle Maschine',
      titlePlural: 'Virtuelle Maschinen',
      create: 'Virtuelle Maschine hinzufügen',
      edit: 'Virtuelle Maschine bearbeiten',

      resources: 'Ressourcen',
      cpuCores: 'CPU Kerne',
      memoryGib: 'Arbeitsspeicher (GiB)',

      startUp: 'Startvorgang',
      runStatus: 'Status',
      runStrategy: 'Start Strategie',
      initScript: 'Initialisierungs Skript',

      osImage: 'Betriebssystem Abbild',
      runStatusValues: {
        running: 'Läuft',
        pending: 'Wartet',
        stopped: 'Angehalten',
      },
      runStrategies: {
        always: 'Immer starten',
        'rerun-on-failure': 'Bei Fehlern neu starten',
        manual: 'Manuell starten',
        halted: 'Gestoppt',
      },
      masquerade: {
        title: 'Netzwerkmodus',
        private: 'Privat',
        public: 'Öffentlich',
        info: 'Der private Modus aktiviert die Netzwerkadressübersetzung (NAT) für die virtuelle Maschine. Die virtuelle Maschine kann auf das Internet zugreifen, ist aber nicht über das Internet erreichbar.',
      },
      publicAddress: 'Öffentliche Adresse',
      networkMounts: {
        default: 'Default Netzwerk',
        custom: 'Benutzerdefinierte Netzwerke',
      },
    },
    disk: {
      title: 'Datenträger',
      titlePlural: 'Datenträger',
      create: 'Datenträger hinzufügen',
      edit: 'Datenträger bearbeiten',
      createDiskSnapshot: 'Momentaufnahme erstellen',

      resources: 'Ressourcen',
      sizeGib: 'Größe (GiB)',
      iops: 'IOPS',
      iopsInfo:
        'IOPS steht für "Input/Output Operations Per Second" und bezieht sich auf die Anzahl der Ein- und Ausgabeoperationen, die ein Speichergerät, wie beispielsweise eine Festplatte oder eine SSD, pro Sekunde verarbeiten kann.',
      accessType: 'Zugriffstyp',
      accessTypes: {
        'read-write-once': 'Einfach les-/schreibbar',
        'read-write-many': 'Mehrfach les-/schreibbar',
      },
      source: {
        title: 'Quelle',
        type: 'Quelltyp',
        none: 'Keine',
        diskRef: 'Datenträger',
        diskSnapshotRef: 'Momentaufnahme',
        imageRef: 'Abbild',
      },

      mount: {
        title: 'Datenträger Mount',
        titlePlural: 'Datenträger Mounts',
        create: 'Datenträger Mount hinzufügen',
        edit: 'Datenträger Mount bearbeiten',
        inUseInfo:
          'Der Datenträger wird von einer anderen virtuellen Machine verwendet und kann nicht eingebunden werden.',
        noneAdded: 'Noch keine Datenträger Mounts hinzugefügt',
        mountType: 'Mount Typ',
        mountTypes: {
          os: 'Betriebssystem',
          other: 'Anderes',
        },
      },
    },
    diskSnapshot: {
      title: 'Momentaufnahme',
      titlePlural: 'Momentaufnahmen',
      create: 'Momentaufnahme hinzufügen',
      edit: 'Momentaufnahme bearbeiten',
      source: 'Quelle',
      diskRef: 'Quelldatenträger',
    },
    image: {
      title: 'Abbild',
      titlePlural: 'Abbilder',
      create: 'Abbild hinzufügen',
      createOsImage: 'Betriebssystem Abbild hinzufügen',
      edit: 'Abbild bearbeiten',
      url: 'Abbild URL',
      osType: 'Betriebssystem Typ',
      osArch: 'Betriebssystem Architektur',
      osTypeOptions: { windows: 'Windows', linux: 'Linux', bsd: 'BSD', other: 'Anderes' },
      osArchOptions: { 'x86-x64': 'x86-x64', other: 'Anderes' },
    },
    backupPolicy: {
      title: 'Sicherungsrichtlinie',
      titlePlural: 'Sicherungsrichtlinien',
      create: 'Sicherungsrichtlinie hinzufügen',
      edit: 'Sicherungsrichtlinie bearbeiten',
      settings: 'Einstellungen',
      schedule: 'Zeitplan',
      retentionDays: 'Speicherdauer',
      retentionDaysLong: 'Speicherdauer (Tage)',
      included: 'Eingeschlossen',
      notIncluded: 'Nicht eingeschlossen',
      includedDisks: 'Datenträger',
      allDisks: 'Alle Datenträger des Projektes',
      specificDisks: 'Spezifische Datenträger',
      toInclude: 'Datenträger einschließen',
      toExclude: 'Datenträger ausschließen',
      allDisksIncluded:
        'Alle Datenträger des Projektes werden gesichert. Dies beinhaltet auch Datenträger, die in Zukunft erstellt werden.',
    },
    network: {
      title: 'Netzwerk',
      titlePlural: 'Netzwerke',
      create: 'Netzwerk hinzufügen',
      edit: 'Netzwerk bearbeiten',
      noneAdded: 'Noch keine Netzwerke hinzugefügt',

      vlanId: 'VLAN ID',
      vlanIdInfo: 'VLAN ID muss zwischen 1 und 4094 liegen.',

      ranges: {
        title: 'Adressbereich',
        titlePlural: 'Adressbereiche',
        create: 'Adressbereich hinzufügen',
        edit: 'Adressbereich bearbeiten',
        noneAdded: 'Noch keine Adressbereiche hinzugefügt',

        subnet: 'Subnetz',
        gateway: 'Gateway',
        rangeStart: 'Adressbereich Anfang',
        rangeEnd: 'Adressbereich Ende',
      },
      routes: {
        title: 'Route',
        titlePlural: 'Routen',
        create: 'Route hinzufügen',
        edit: 'Route bearbeiten',
        noneAdded: 'Noch keine Routen hinzugefügt',

        destination: 'Ziel',
        gateway: 'Gateway',
      },
    },
    networkServicePolicy: {
      title: 'Netzwerk Service Richtlinie',
      titlePlural: 'Netzwerk Service Richtlinien',
      info: 'Netzwerk Service Richtlinien ermöglichen den Zugriff auf Dienste, die auf Ihrer VM laufen. Der Zugriff kann intern innerhalb der Umgebung und/oder extern über das Internet erfolgen.',
      services: {
        title: 'Service',
        titlePlural: 'Services',
        create: 'Service hinzufügen',
        edit: 'Service bearbeiten',
        noneAdded: 'Noch keine Services hinzugefügt',

        internalName: 'Interner Name',
        externalDomainNames: {
          title: 'Externer Domainname',
          titlePlural: 'Externe Domainnamen',
          create: 'Externen Domainname hinzufügen',
          edit: 'Externen Domainname bearbeiten',
          noneAdded: 'Noch keine externen Domainnamen hinzugefügt',
        },
        protocol: 'Protokoll',
        protocolOptions: {
          http: 'HTTP',
          tcp: 'TCP',
        },
        sourcePort: 'Quellport',
        targetPort: 'Zielport',
        info: {
          internalName:
            'Name für den Dienst, der zur Identifizierung innerhalb der Umgebung verwendet wird. Andere Dienste im Projekt können diesen Namen verwenden, um auf diesen Dienst auf der VM zuzugreifen. Muss innerhalb eines Projekts eindeutig sein.',
          externalDomainNames:
            'Liste der externen Domainnamen für den öffentlichen Zugang. Der Dienst auf der VM kann über diese Domainnamen aus dem Internet erreicht werden. Die Domainnamen müssen registriert sein und auf die öffentliche Adresse der VM zeigen. Diese kann nach Erstellung der VM abgerufen werden.',
          protocol:
            'Wählen Sie das Kommunikationsprotokoll. Wenn Sie HTTP wählen, wird für den öffentlichen Zugang automatisch TLS (HTTPS) eingerichtet. Intern wird HTTP verwendet.',
          sourcePort:
            'Geben Sie den Port an, unter dem der Dienst intern erreichbar sein soll. Der Dienst kann intern unter <interner Name>:<Quellport> erreicht werden. Ein Webserver könnte z.B. auf Port 8080 der VM laufen, soll aber über den Standard-HTTP-Port 80 erreicht werden. In diesem Fall wählen Sie 8080 als Zielport und 80 als Quellport.',
          targetPort:
            'Geben Sie den Port an, unter dem der Dienst auf der VM ausgeführt wird. Ein Webserver könnte z.B. auf Port 8080 der VM laufen, soll aber über den Standard-HTTP-Port 80 erreicht werden. In diesem Fall wählen Sie 8080 als Zielport und 80 als Quellport.',
        },
      },
    },
  },
  pages: {
    login: {
      title: 'Login',
      loginMessage:
        'Sie sind aktuell nicht angemeldet.\nMelden Sie sich mit Ihrem FUNKE Cloud Service Konto an.',
      loginButton: 'Anmelden',
    },
    dashboard: {
      title: 'Dashboard',
      counts: {
        projects: 'Projekte',
        clusters: 'Cluster',
        containers: 'Container',
        virtualMachines: 'Virtuelle Maschinen',
        disks: 'Datenträger',
        networks: 'Netzwerke',
        images: 'Abbilder',
      },
      resources: 'Ressourcen',
      capacity: {
        title: 'Kapazität',
        cpuCores: 'CPU Kerne',
        memoryGib: 'Arbeitsspeicher (GiB)',
        storageGib: 'Speicherplatz (GiB)',
        storageIops: 'Speicher IOPS',
      },
      overview: 'Übersicht',
    },
    projects: {
      title: 'Projekte',
      noData: 'Keine Projekte vorhanden',

      popups: {
        delete: {
          title: amount =>
            `Sind Sie sicher, dass Sie diese${amount !== 1 ? '' : 's'} Projekt${
              amount !== 1 ? 'e' : ''
            } löschen möchten?`,
          toDelete: amount =>
            `Folgende${amount === 1 ? 'r' : ''} Projekt${amount !== 1 ? 'e' : ''} ${
              amount === 1 ? 'wird' : 'werden'
            } gelöscht:`,
          description: 'Diese Aktion kann nicht rückgängig gemacht werden',
        },
      },
      notifications: {
        delete: {
          success: (amount, amountFailed) =>
            `${amount} Projekt${amount !== 1 ? 'e' : ''} gelöscht` +
            (amountFailed != null && amountFailed > 0 ? `(${amountFailed} fehlgeschlagen)` : ''),
          error: amount => `Fehler beim Löschen von ${amount} Projekt${amount !== 1 ? 'e' : ''}`,
          pending: amount => `Lösche ${amount} Projekt${amount !== 1 ? 'e' : ''}...`,
        },
        create: 'Projekt erstellt',
        update: 'Projekt aktualisiert',
      },
    },
    projectQuotaRequests: {
      title: 'Kontingentanfragen',
      pendingRequests: 'Offene Anfragen',
      answeredRequests: 'Beantwortete Anfragen',
      accept: 'Akzeptieren',
      reject: 'Ablehnen',
      accepted: 'Akzeptiert',
      rejected: 'Abgelehnt',
      pending: 'Offen',
      noData: 'Keine Kontingentanfragen vorhanden',
      quota: 'Kontingent',
      currentValue: 'Aktuell',
      newValue: 'Neuer Wert',
      status: 'Status',
      sender: 'Benutzer',
      maxCpuCores: 'CPU Kerne',
      maxMemoryGib: 'Arbeitsspeicher (GiB)',
      maxDiskSizeGib: 'Datenträgergröße (GiB)',
      maxDiskIops: 'Datenträger IOPS',
      maxVirtualMachineCount: 'Virtuelle Maschinen',
      maxClusterCount: 'Cluster',
      maxContainerCount: 'Container',
      maxDiskCount: 'Datenträger',
      maxDiskCountPerVirtualMachine: 'Datenträger pro virtueller Maschine',
      maxNetworkCount: 'Netzwerke',
      maxNetworkCountPerVirtualMachine: 'Netzwerke pro virtueller Maschine',
      maxNetworkCountPerCluster: 'Netzwerke pro Cluster',
      maxNetworkCountPerContainer: 'Netzwerke pro Container',
      notifications: {
        accepted: 'Projektkontingent wurde angepasst',
        rejected: 'Kontingentanfrage wurde abgelehnt',
      },
    },
    clusters: {
      title: 'Cluster',
      noData: 'Keine Cluster vorhanden',

      buttons: {
        downloadKubeconfig: 'Kubeconfig',
      },

      popups: {
        delete: {
          title: amount =>
            `Sind Sie sicher, dass Sie diese${amount != 1 ? '' : 'n'} Cluster löschen möchten?`,
          description: 'Diese Aktion kann nicht rückgängig gemacht werden',
          toDelete: amount =>
            `Folgende${amount === 1 ? 'r' : ''} Cluster ${
              amount === 1 ? 'wird' : 'werden'
            } gelöscht:`,
        },
      },
      notifications: {
        delete: {
          success: (amount, amountFailed) =>
            `${amount} Cluster gelöscht` +
            (amountFailed != null && amountFailed > 0 ? `(${amountFailed} fehlgeschlagen)` : ''),
          error: amount => `Fehler beim Löschen von ${amount} Cluster${amount !== 1 ? 'n' : ''}`,
          pending: amount => `Lösche ${amount} Cluster...`,
        },
        updateRunStatus: {
          success: (runStatus: 'running' | 'stopped') =>
            `Cluster wurde ${runStatus === 'running' ? 'gestartet' : 'gestoppt'}`,
          error: (runStatus: 'running' | 'stopped') =>
            `Fehler beim ${runStatus === 'running' ? 'Starten' : 'Stoppen'} des Clusters`,
          pending: (runStatus: 'running' | 'stopped') =>
            `Cluster wird ${runStatus === 'running' ? 'gestartet' : 'gestoppt'}...`,
        },
        create: 'Cluster erstellt',
        update: 'Cluster aktualisiert',
      },
    },
    installedApps: {
      title: 'Installierte Apps',
      noData: 'Keine Apps installiert',

      popups: {
        uninstall: {
          title: amount =>
            `Sind Sie sicher, dass Sie diese App${amount == 1 ? '' : 's'} deinstallieren möchten?`,
          description: 'Diese Aktion kann nicht rückgängig gemacht werden',
          toDelete: amount => `Folgende App${amount == 1 ? ' wird' : 's werden'} deinstalliert:`,
        },
      },
      notifications: {
        uninstall: {
          success: (amount, amountFailed) =>
            `${amount} App${amount == 1 ? '' : 's'} deinstalliert` +
            (amountFailed != null && amountFailed > 0 ? `(${amountFailed} fehlgeschlagen)` : ''),
          error: amount => `Fehler beim Deinstallieren von ${amount} App${amount == 1 ? '' : 's'}`,
          pending: amount => `Deinstalliere ${amount} App${amount == 1 ? '' : 's'}...`,
        },
        create: 'App installiert',
        update: 'App aktualisiert',
      },
    },
    appStore: {
      title: 'App Store',
      noData: 'Keine Apps vorhanden',
    },
    secrets: {
      title: 'Geheimnisse',
      noData: 'Keine Geheimnisse vorhanden',

      popups: {
        delete: {
          title: amount =>
            `Sind Sie sicher, dass Sie diese${amount === 1 ? 's' : ''} Geheimnis${
              amount === 1 ? '' : 'se'
            } löschen möchten?`,
          description: 'Diese Aktion kann nicht rückgängig gemacht werden',
          toDelete: amount =>
            `Folgende${amount === 1 ? 's' : ''} Geheimnis${
              amount === 1 ? ' wird' : 'se werden'
            } gelöscht:`,
        },
      },
      notifications: {
        delete: {
          success: (amount, amountFailed) =>
            `${amount} Geheimnis${amount === 1 ? '' : 'se'} gelöscht` +
            (amountFailed != null && amountFailed > 0 ? `(${amountFailed} fehlgeschlagen)` : ''),
          error: amount =>
            `Fehler beim Löschen von ${amount} Geheimnis${amount === 1 ? '' : 'sen'}`,
          pending: amount => `Lösche ${amount} Geheimnis${amount === 1 ? '' : 'se'}...`,
        },
        create: 'Geheimnis erstellt',
        update: 'Geheimnis aktualisiert',
      },
    },
    configurations: {
      title: 'Konfigurationen',
      noData: 'Keine Konfigurationen vorhanden',

      popups: {
        delete: {
          title: amount =>
            `Sind Sie sicher, dass Sie diese Konfiguration${
              amount === 1 ? '' : 'en'
            } löschen möchten?`,
          description: 'Diese Aktion kann nicht rückgängig gemacht werden',
          toDelete: amount =>
            `Folgende Konfiguration${amount === 1 ? ' wird' : 'en werden'} gelöscht:`,
        },
      },
      notifications: {
        delete: {
          success: (amount, amountFailed) =>
            `${amount} Konfiguration${amount === 1 ? '' : 'en'} gelöscht` +
            (amountFailed != null && amountFailed > 0 ? `(${amountFailed} fehlgeschlagen)` : ''),
          error: amount =>
            `Fehler beim Löschen von ${amount} Konfiguration${amount === 1 ? '' : 'en'}`,
          pending: amount => `Lösche ${amount} Konfiguration${amount === 1 ? '' : 'en'}...`,
        },
        create: 'Konfiguration erstellt',
        update: 'Konfiguration aktualisiert',
      },
    },
    containers: {
      title: 'Container',
      noData: 'Keine Container vorhanden',
      gpu: {
        enabled: 'Aktiviert',
        disabled: 'Deaktiviert',
      },
      networking: 'Networking',
      popups: {
        delete: {
          title: amount =>
            `Sind Sie sicher, dass Sie diese${amount != 1 ? '' : 'n'} Container löschen möchten?`,
          description: 'Diese Aktion kann nicht rückgängig gemacht werden',
          toDelete: amount =>
            `Folgende${amount === 1 ? 'r' : ''} Container ${
              amount === 1 ? 'wird' : 'werden'
            } gelöscht:`,
        },
      },
      notifications: {
        delete: {
          success: (amount, amountFailed) =>
            `${amount} Container gelöscht` +
            (amountFailed != null && amountFailed > 0 ? `(${amountFailed} fehlgeschlagen)` : ''),
          error: amount => `Fehler beim Löschen von ${amount} Container${amount !== 1 ? 'n' : ''}`,
          pending: amount => `Lösche ${amount} Container...`,
        },
        updateRunStatus: {
          success: (runStatus: 'running' | 'stopped') =>
            `Container wurde ${runStatus === 'running' ? 'gestartet' : 'gestoppt'}`,
          error: (runStatus: 'running' | 'stopped') =>
            `Fehler beim ${runStatus === 'running' ? 'Starten' : 'Stoppen'} des Containers`,
          pending: (runStatus: 'running' | 'stopped') =>
            `Container wird ${runStatus === 'running' ? 'gestartet' : 'gestoppt'}...`,
        },
        create: 'Container erstellt',
        update: 'Container aktualisiert',
      },
    },
    virtualMachines: {
      title: 'Virtuelle Maschinen',
      noData: 'Keine Virtuellen Maschinen vorhanden',
      details: {
        resources: 'Ressourcen',
        vnc: {
          title: 'VNC Konsole',
        },
      },

      popups: {
        delete: {
          title: amount =>
            `Sind Sie sicher, dass Sie diese virtuelle Maschine${
              amount !== 1 ? 'n' : ''
            } löschen möchten?`,
          toDelete: amount =>
            `Folgende virtuelle Maschine${amount !== 1 ? 'n' : ''} ${
              amount === 1 ? 'wird' : 'werden'
            } gelöscht:`,
          description: 'Diese Aktion kann nicht rückgängig gemacht werden',
        },
      },
      notifications: {
        updateRunStrategy: {
          success: (runStrategy: 'always' | 'rerun-on-failure' | 'manual' | 'halted') =>
            `Start Strategie wurde auf ${runStrategy} gesetzt`,
          error: (runStrategy: 'always' | 'rerun-on-failure' | 'manual' | 'halted') =>
            `Fehler beim Setzen der Start Strategie auf ${runStrategy}`,
          pending: (runStrategy: 'always' | 'rerun-on-failure' | 'manual' | 'halted') =>
            `Setze Start Strategie auf ${runStrategy}...`,
        },
        delete: {
          success: (amount, amountFailed) =>
            `${amount} virtuelle Maschine${amount !== 1 ? 'n' : ''} gelöscht` +
            (amountFailed != null && amountFailed > 0 ? `(${amountFailed} fehlgeschlagen)` : ''),
          error: amount =>
            `Fehler beim Löschen von ${amount} virtuellen Maschine${amount !== 1 ? 'n' : ''}`,
          pending: amount => `Lösche ${amount} virtuelle Maschine${amount !== 1 ? 'n' : ''}...`,
        },
        create: 'Virtuelle Machine erstellt',
        update: 'Virtuelle Machine aktualisiert',
      },
    },
    disks: {
      title: 'Datenträger',
      noData: 'Keine Datenträger vorhanden',

      popups: {
        delete: {
          title: amount =>
            `Sind Sie sicher, dass Sie ${
              amount != 1 ? 'diese Datenträger' : 'diesen Datenträger'
            } löschen möchten?`,
          description: 'Diese Aktion kann nicht rückgängig gemacht werden',
          toDelete: amount =>
            `Folgende${amount === 1 ? 'r' : ''} Datenträger ${
              amount === 1 ? 'wird' : 'werden'
            } gelöscht:`,
          notToDelete: amount =>
            `Folgende${amount === 1 ? 'r' : ''} Datenträger ${
              amount === 1 ? 'kann' : 'können'
            } nicht gelöscht werden, da ${amount === 1 ? 'er' : 'sie'} noch in Verwendung ${
              amount === 1 ? 'ist' : 'sind'
            }:`,
        },
        restore: {
          title: 'Datenträger wiederherstellen',
          description: 'Der Datenträger wird mit der folgenden Momentaufnahme überschrieben:',
          warn: 'Diese Aktion führt zu Datenverlust.',
        },
      },
      notifications: {
        restore: {
          success: 'Datenträger wiederhergestellt',
          error: 'Fehler beim Wiederherstellen des Datenträgers',
          pending: 'Wiederherstellen des Datenträgers...',
        },
        delete: {
          success: (amount: number, amountFailed?: number) =>
            `${amount} Datenträger gelöscht` +
            (amountFailed != null && amountFailed > 0 ? `(${amountFailed} fehlgeschlagen)` : ''),
          error: amount =>
            `Fehler beim Löschen von ${amount} Datenträger${amount !== 1 ? 'n' : ''}`,
          pending: amount => `Lösche ${amount} Datenträger...`,
        },
        create: 'Datenträger erstellt',
        update: 'Datenträger aktualisiert',
      },
    },
    diskSnapshots: {
      title: 'Momentaufnahmen',
      noData: 'Keine Momentaufnahmen vorhanden',

      popups: {
        delete: {
          title: amount =>
            `Sind Sie sicher, dass Sie diese Momentaufnahme${
              amount !== 1 ? 'n' : ''
            } löschen möchten?`,
          toDelete: amount =>
            `Folgende Momentaufnahme${amount !== 1 ? 'n' : ''} ${
              amount === 1 ? 'wird' : 'werden'
            } gelöscht:`,
          description: 'Diese Aktion kann nicht rückgängig gemacht werden',
        },
      },
      notifications: {
        delete: {
          success: (amount, amountFailed) =>
            `${amount} Momentaufnahme${amount !== 1 ? 'n' : ''} gelöscht` +
            (amountFailed != null && amountFailed > 0 ? `(${amountFailed} fehlgeschlagen)` : ''),
          error: amount =>
            `Fehler beim Löschen von ${amount} Momentaufnahme${amount !== 1 ? 'n' : ''}`,
          pending: amount => `Lösche ${amount} Momentaufnahme${amount !== 1 ? 'n' : ''}...`,
        },
        create: 'Momentaufnahme erstellt',
        update: 'Momentaufnahme aktualisiert',
      },
    },
    images: {
      title: 'Abbilder',
      noData: 'Keine Abbilder vorhanden',
      type: 'Typ',
      typeOs: 'Betriebssystem',
      typeOsShort: 'Betriebssystem',
      typeData: 'Daten',
      popups: {
        delete: {
          title: amount =>
            `Sind Sie sicher, dass Sie ${
              amount != 1 ? 'diese Abbilder' : 'dieses Abbild'
            } löschen möchten?`,
          description: 'Diese Aktion kann nicht rückgängig gemacht werden',
          toDelete: (amount: number) =>
            `Folgende${amount === 1 ? 's' : ''} Abbild${amount === 1 ? '' : 'er'} ${
              amount === 1 ? 'wird' : 'werden'
            } gelöscht:`,
          notToDelete: (amount: number) =>
            `Folgende${amount === 1 ? 's' : ''} Abbild ${
              amount === 1 ? 'kann' : 'können'
            } nicht gelöscht werden, da ${amount === 1 ? 'es' : 'sie'} noch in Verwendung ${
              amount === 1 ? 'ist' : 'sind'
            }:`,
        },
      },
      notifications: {
        delete: {
          success: (amount, amountFailed) =>
            `${amount} Abbild${amount !== 1 ? 'er' : ''} gelöscht` +
            (amountFailed != null && amountFailed > 0 ? `(${amountFailed} fehlgeschlagen)` : ''),
          error: amount => `Fehler beim Löschen von ${amount} Abbild${amount !== 1 ? 'ern' : ''}`,
          pending: amount => `Lösche ${amount} Abbild${amount !== 1 ? 'er' : ''}...`,
        },
        create: 'Abbild erstellt',
        update: 'Abbild aktualisiert',
      },
    },
    backupPolicies: {
      title: 'Sicherungsrichtlinien',
      noData: 'Keine Sicherungsrichtlinien vorhanden',
      popups: {
        delete: {
          title: amount =>
            `Sind Sie sicher, dass Sie diese Sicherungsrichtlinie${
              amount != 1 ? 'n' : ''
            } löschen möchten?`,
          description: 'Diese Aktion kann nicht rückgängig gemacht werden',
          toDelete: (amount: number) =>
            `Folgende Sicherungsrichtlinie${amount === 1 ? '' : 'n'} ${
              amount === 1 ? 'wird' : 'werden'
            } gelöscht:`,
          notToDelete: (amount: number) =>
            `Folgende Sicherungsrichtlinie${
              amount === 1 ? ' kann' : 'n können'
            } nicht gelöscht werden, da sie ${
              amount === 1 ? 'eine ' : ''
            }default Sicherungsrichtlinie${amount === 1 ? ' ist' : 'n sind'}:`,
        },
      },
      notifications: {
        delete: {
          success: (amount, amountFailed) =>
            `${amount} Sicherungsrichtlinie${amount !== 1 ? 'n' : ''} gelöscht` +
            (amountFailed != null && amountFailed > 0 ? `(${amountFailed} fehlgeschlagen)` : ''),
          error: amount =>
            `Fehler beim Löschen von ${amount} Sicherungsrichtlinie${amount !== 1 ? 'n' : ''}`,
          pending: amount => `Lösche ${amount} Sicherungsrichtlinie${amount !== 1 ? 'n' : ''}...`,
        },
        create: 'Sicherungsrichtlinie erstellt',
        update: 'Sicherungsrichtlinie aktualisiert',
      },
    },
    networks: {
      title: 'Netzwerke',
      noData: 'Keine Netzwerke vorhanden',
      noRanges: 'Keine Adressbereiche vorhanden',
      noRoutes: 'Keine Routen vorhanden',

      popups: {
        delete: {
          title: amount =>
            `Sind Sie sicher, dass Sie ${
              amount !== 1 ? 'diese Netzwerke' : 'dieses Netzwerk'
            } löschen möchten?`,
          toDelete: amount =>
            `Folgende${amount === 1 ? 's' : ''} Netzwerk${amount !== 1 ? 'e' : ''} ${
              amount === 1 ? 'wird' : 'werden'
            } gelöscht:`,
          description: 'Diese Aktion kann nicht rückgängig gemacht werden',
        },
      },
      notifications: {
        delete: {
          success: (amount, amountFailed) =>
            `${amount} Netzwerk${amount !== 1 ? 'e' : ''} gelöscht` +
            (amountFailed != null && amountFailed > 0 ? `(${amountFailed} fehlgeschlagen)` : ''),
          error: amount => `Fehler beim Löschen von ${amount} Netzwerk${amount !== 1 ? 'e' : ''}`,
          pending: amount => `Lösche ${amount} Netzwerk${amount !== 1 ? 'e' : ''}...`,
        },
        create: 'Netzwerk erstellt',
        update: 'Netzwerk aktualisiert',
      },
    },
    networkServicePolicies: {
      title: 'Netzwerk Service Richtlinie',
      noData: 'Keine Netzwerk Service Richtlinie vorhanden',

      popups: {
        delete: {
          title: amount =>
            `Sind Sie sicher, dass Sie diese Netzwerk Service Richtlinie${
              amount !== 1 ? 'n' : ''
            } löschen möchten?`,
          description: 'Diese Aktion kann nicht rückgängig gemacht werden',
        },
      },
    },
  },
} satisfies MissingKeys

export default de
