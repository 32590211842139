import { type DeepPartial } from '../../types/helpers'

const en = {
  general: {
    cancel: 'Cancel',
    save: 'Save',
    restore: 'Restore',
    delete: 'Delete',
    deleteNone: 'None',
    deleteMore: (amount: number) => `... and ${amount} more`,
    edit: 'Edit',
    add: 'Add',
    create: 'Create',
    start: 'Start',
    stop: 'Stop',
    show: 'Show',
    hide: 'Hide',
    showAll: 'Show all',
    hideAll: 'Hide all',
    showDetails: 'Show details',
    hideDetails: 'Hide details',
    showMore: 'Show more',
    showLess: 'Show less',
    showPassword: 'Show password',
    hidePassword: 'Hide password',
    copy: 'Copy',
    copied: 'Copied',
    next: 'Next',
    previous: 'Previous',
    available: 'Available',
    selected: 'Selected',
    selection: 'Selection',
    enabled: 'Enabled',
    disabled: 'Disabled',
    noItems: 'No items available',
    profileMenuBtn: 'Open settings menu',
    createNew: 'Create new',
    useExisting: 'Use existing',
    close: 'Close',
    search: 'Search',
    current: 'Current',
    information: 'Information',
    warning: 'Warning',
    error: 'Error',
    default: 'Default',
    sortAscending: 'Sort ascending',
    sortDescending: 'Sort descending',
    notAvailableInLocalMode: 'Not available in local mode',
    timeAgo: (info: { days?: number; hours?: number; minutes?: number }) =>
      info.days
        ? `${info.days} day${info.days !== 1 ? 's' : ''}`
        : info.hours
        ? `${info.hours} hour${info.hours !== 1 ? 's' : ''}`
        : info.minutes !== undefined
        ? `${info.minutes} minute${info.minutes !== 1 ? 's' : ''}`
        : '',
    cron: {
      wizard: 'Wizard',
      repeatEvery: 'Repeat every',
      amount: 'Amount',
      mode: 'Mode',
      modes: {
        minutes: 'Minutes',
        hours: 'Hours',
        days: 'Days (of the month)',
        weeks: 'Weeks',
        months: 'Months (of the year)',
        years: 'Years',
      },
      time: 'Time',
      hours: 'Hours',
      minutes: 'Minutes',
      onWeekdays: 'On weekdays',
      onMonths: 'On months',
      runs: 'Runs',
      nextRuns: (amount: number) => `Next ${amount} runs`,
      invalid: 'Invalid cron expression',
      showCalendar: 'Show calendar',
      hideCalendar: 'Hide calendar',
    },
    weekdays: {
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday',
    },
    weekdaysShort: {
      monday: 'Mo',
      tuesday: 'Tu',
      wednesday: 'We',
      thursday: 'Th',
      friday: 'Fr',
      saturday: 'Sa',
      sunday: 'Su',
    },
    months: {
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December',
    },
    groups: {
      admin: 'Administrator',
      projectManager: 'Project Manager',
      dev: 'Developer',
    },
    errors: {
      sessionRenew: 'Your login session has expired. Please log in again.',
      login: 'There was an error logging in. Please try again later.',
      add: 'There was an error adding the item. Please try again later.',
      update: 'There was an error updating the item. Please try again later.',
      error: 'An error has occurred. Please try again in a few minutes.',
      retry: 'Try again',
      notFound: 'Page not found',
      fetch: 'There was an error fetching data.',
      retryFetch: 'Retrying to fetch data...',
      connection: 'There was an error connecting to the server.',

      input: {
        required: 'This field is required',
        unique: 'This field must be unique',
        number: 'This field must be a number',
        positive: 'This field must be a positive number',
        min: (min: number) => `This field must be at least ${min} characters long`,
        max: (max: number) => `This field must be at most ${max} characters long`,
        minAmount: (min: number) => `This field must be at least ${min}`,
        maxAmount: (max: number) => `This field must be at most ${max}`,
        minAdded: (min: number, name: string) => `Add at least ${min} ${name}`,
        maxAdded: (min: number, name: string) => `Add at most ${min} ${name}`,
        minSelection: (min: number) => `Select at least ${min} item${min === 1 ? '' : 's'}`,
        email: 'This field must be a valid email address',
        url: 'This field must be a valid URL',
        projectName:
          'This field may only contain the characters a-z, 0-9 and - and must start with a letter',
        dnsSubdomainName:
          'This field may only contain the characters a-z, 0-9, - and . and must start and end with a letter or number',
        ip: 'This field must be a valid IP address',
        ipWithMask: 'This field must be a valid IP address with mask',
        domainName: 'This field must be a valid domain name',
        projectNameUnique: 'This project name is already in use',
      },
    },
  },
  settings: {
    title: 'Settings',
    language: 'Language',
    theme: 'Theme',
    theme_light: 'Light',
    theme_dark: 'Dark',
    theme_system: 'System',
    logout: 'Logout',
    enterAdminPanel: 'Enter Admin Panel',
    leaveAdminPanel: 'Leave Admin Panel',
  },
  navigation: {
    skipNavigation: 'Skip navigation',
    dashboard: 'Dashboard',
    projects: 'Projects',
    projectQuotaRequests: 'Quota Requests',
    computing: 'Computing',
    clusters: {
      title: 'Clusters',
      details: 'Details',
      configurations: 'Configurations',
      secrets: 'Secrets',
      apps: 'Apps',
      appStore: 'App Store',
      installedApps: 'Installed Apps',
    },
    containers: 'Containers',
    virtualMachines: 'Virtual Machines',
    storage: 'Storage',
    disks: 'Disks',
    images: 'Images',
    diskSnapshots: 'Snapshots',
    backupPolicies: 'Backup Policies',
    networks: 'Networks',
    termsOfService: 'Terms',
    privacyPolicy: 'Privacy',
    imprint: 'Imprint',
    version: (version: string) => `Version: ${version}`,
  },
  forms: {
    basic: 'Basic',

    metadata: {
      title: 'Metadata',

      projectName: 'Project',
      namespace: 'Namespace',
      name: 'Name',
      displayName: 'Display Name',
      description: 'Description',
      createdAt: 'Created',
      updatedAt: 'Updated',
    },
    project: {
      title: 'Project',
      titlePlural: 'Projects',
      create: 'Add Project',
      edit: 'Edit Project',

      groupPrefix: 'Group Prefix',
      costCenter: 'Cost Center',
    },
    projectQuotaRequest: {
      increase: 'Request more',
      request: 'Request Quota',
      description:
        'Each project has limited resources available. You can request an increase of the quota.',
      newMax: 'New maximum',
      currentQuota: (max: number) => `Current quota: ${max}`,
      maxCpuCores: 'Request CPU Cores',
      maxMemoryGib: 'Request Memory (GiB)',
      maxDiskSizeGib: 'Request Disk size (GiB)',
      maxDiskIops: 'Request Disk IOPS',
      maxVirtualMachineCount: 'Request virtual machine quota',
      maxClusterCount: 'Request cluster quota',
      maxContainerCount: 'Request container quota',
      maxDiskCount: 'Request disk quota',
      maxDiskCountPerVirtualMachine: 'Request disk quota per virtual machine',
      maxNetworkCount: 'Request network quota',
      maxNetworkCountPerVirtualMachine: 'Request network quota per virtual machine',
      maxNetworkCountPerCluster: 'Request network quota per cluster',
      maxNetworkCountPerContainer: 'Request network quota per container',
      sentNotification: 'The quota request was sent successfully',
      alert: {
        maxCpuCores:
          'The selection of CPU cores is restricted because there are no more resources available in the project.',
        maxMemoryGib:
          'The selection of memory is restricted because there are no more resources available in the project.',
        maxDiskSizeGib:
          'The selection of the disks size is restricted because there are no more resources available in the project.',
        maxDiskIops:
          'The selection of disk IOPS is restricted because there are no more resources available in the project.',
        maxVirtualMachineCount:
          'Creating this virtual machine is not possible because there are no more resources available in the project.',
        maxClusterCount:
          'Creating this cluster is not possible because there are no more resources available in the project.',
        maxContainerCount:
          'Creating this container is not possible because there are no more resources available in the project.',
        maxDiskCount:
          'Creating this disk is not possible because there are no more resources available in the project.',
        maxDiskCountPerVirtualMachine:
          'Adding another disk is not possible because the number of disks per virtual machine is limited in the project.',
        maxNetworkCount:
          'Creating this network is not possible because there are no more resources available in the project.',
        maxNetworkCountPerVirtualMachine:
          'Adding another network is not possible because the number of networks per virtual machine is limited in the project.',
        maxNetworkCountPerCluster:
          'Adding another network is not possible because the number of networks per cluster is limited in the project.',
        maxNetworkCountPerContainer:
          'Adding another network is not possible because the number of networks per container is limited in the project.',
      },
    },
    cluster: {
      title: 'Cluster',
      titlePlural: 'Clusters',
      create: 'Add Cluster',
      edit: 'Edit Cluster',

      kubernetesVersion: 'Kubernetes Version',
      networkMounts: 'Network Mounts',
      runStatus: 'Status',
      runStatusValues: {
        running: 'Running',
        pending: 'Pending',
        stopped: 'Stopped',
      },
      controlPlaneUrl: 'Control Plane URL',
    },
    app: {
      title: 'App',
      titlePlural: 'Apps',
      install: 'Install',
      uninstall: 'Uninstall',

      configure: (name: string) => `Configure ${name} App`,
      parameters: 'Parameters',

      version: 'Version',
      docsUrl: 'Documentation',
      repoUrl: 'Repository',
      category: {
        title: 'Category',
        database: 'Database',
        webserver: 'Webserver',
        monitoring: 'Monitoring',
      },
      categoryPlural: {
        database: 'Databases',
        webserver: 'Webserver',
        monitoring: 'Monitoring',
      },
    },
    secret: {
      title: 'Secret',
      titlePlural: 'Secrets',
      create: 'Add Secret',
      edit: 'Edit Secret',

      dockerConfigData: {
        registry: 'Registry',
        username: 'Username',
        password: 'Password',
      },
      type: {
        title: 'Type',
        opaque: 'Custom',
        dockerConfig: 'Docker Config',
        other: 'Other',
      },
      data: {
        title: 'Data',
        key: 'Key',
        value: 'Value',
        noData: 'No value has been added yet',
        create: 'Add value',
      },
    },
    configuration: {
      title: 'Configuration',
      titlePlural: 'Configurations',
      create: 'Add Configuration',
      edit: 'Edit Configuration',

      data: {
        title: 'Data',
        key: 'Key',
        value: 'Value',
        noData: 'No value has been added yet',
        create: 'Add value',
      },
    },
    container: {
      title: 'Container',
      titlePlural: 'Containers',
      create: 'Add Container',
      edit: 'Edit Container',
      runStatus: 'Status',
      runStatusValues: {
        running: 'Running',
        pending: 'Pending',
        stopped: 'Stopped',
      },
      resources: {
        title: 'Resources',
        cpuCores: 'CPU Cores',
        memoryGib: 'Memory (GiB)',
      },
      scaling: {
        title: 'Scaling',
        replicas: 'Replicas',
        minReplicas: 'Minimum Replicas',
        maxReplicas: 'Maximum Replicas',
        autoscaling: {
          title: 'Autoscaling',
          automatic: 'Automatic',
          manual: 'Manual',
        },
        autoscaleCpuUsage: 'CPU Usage in %',
        autoscaleMemoryUsage: 'Memory Usage in %',
      },
      connectivity: {
        title: 'Connectivity',
        containerPort: 'Container Port',
        ingress: {
          title: 'Ingress',
          enabled: 'Ingress enabled',
          disabled: 'Ingress disabled',
          info: 'Ingress allows external access to the container.',
        },
        externalDomain: 'External Domain',
      },
      gpu: {
        title: 'GPU',
        enabled: 'GPU enabled',
        disabled: 'GPU disabled',
        info: 'Using GPU resources in the cluster boosts performance for Machine Learning and compute-intensive tasks.',
      },
      source: {
        title: 'Source',
        type: 'Source Type',
        registry: 'Registry',
        registryName: 'Name',
        registryTag: 'Tag',
        registryUrl: 'URL',
        registryUsername: 'Username',
        registryPassword: 'Password',
        git: 'Git Repository',
        gitUrl: 'URL',
        gitSsh: 'SSH',
        gitSshPublicKey: 'Public Key',
        gitHttps: 'HTTPS',
        gitHttpsUsername: 'Username',
        gitHttpsPassword: 'Password',
      },
    },
    virtualMachine: {
      title: 'Virtual Machine',
      titlePlural: 'Virtual Machines',
      create: 'Add Virtual Machine',
      edit: 'Edit Virtual Machine',

      resources: 'Resources',
      cpuCores: 'CPU Cores',
      memoryGib: 'Memory (GiB)',

      startUp: 'Start Up',
      runStatus: 'Status',
      runStrategy: 'Run Strategy',
      initScript: 'Init Script',

      osImage: 'OS Image',
      runStatusValues: {
        running: 'Running',
        pending: 'Pending',
        stopped: 'Stopped',
      },
      runStrategies: {
        always: 'Always start',
        'rerun-on-failure': 'Restart on failure',
        manual: 'Manual start',
        halted: 'Halted',
      },
      masquerade: {
        title: 'Network Mode',
        private: 'Private',
        public: 'Public',
        info: 'Private mode enables network address translation (NAT) for the virtual machine. The virtual machine can access the internet but is not accessible from the internet.',
      },
      publicAddress: 'Public Address',
      networkMounts: {
        default: 'Default Network',
        custom: 'Custom Networks',
      },
    },
    disk: {
      title: 'Disk',
      titlePlural: 'Disks',
      create: 'Add Disk',
      edit: 'Edit Disk',
      createDiskSnapshot: 'Create Snapshot',

      resources: 'Resources',
      sizeGib: 'Size (GiB)',
      iops: 'IOPS',
      iopsInfo:
        'IOPS stands for "Input/Output Operations Per Second" and refers to the number of input and output operations that a storage device, such as a hard drive or SSD, can perform in one second.',
      accessType: 'Access Type',
      accessTypes: { 'read-write-once': 'Read/write once', 'read-write-many': 'Read/write many' },
      source: {
        title: 'Source',
        type: 'Source Type',
        none: 'None',
        diskRef: 'Disk',
        diskSnapshotRef: 'Snapshot',
        imageRef: 'Image',
      },

      mount: {
        title: 'Disk Mount',
        titlePlural: 'Disk Mounts',
        create: 'Add Disk Mount',
        edit: 'Edit Disk Mount',
        inUseInfo: 'This disk is in use by another virtual machine and cannot be mounted.',
        noneAdded: 'No Disk Mount has been added yet',
        mountType: 'Mount Type',
        mountTypes: {
          os: 'OS',
          other: 'Other',
        },
      },
    },
    diskSnapshot: {
      title: 'Snapshot',
      titlePlural: 'Snapshots',
      create: 'Add Snapshot',
      edit: 'Edit Snapshot',
      source: 'Source',
      diskRef: 'Source disk',
    },
    image: {
      title: 'Image',
      titlePlural: 'Images',
      create: 'Add Image',
      createOsImage: 'Add OS Image',
      edit: 'Edit Image',
      url: 'Image URL',
      osType: 'OS Type',
      osArch: 'OS Architecture',
      osTypeOptions: { windows: 'Windows', linux: 'Linux', bsd: 'BSD', other: 'Other' },
      osArchOptions: { 'x86-x64': 'x86-x64', other: 'Other' },
    },
    backupPolicy: {
      title: 'Backup Policy',
      titlePlural: 'Backup Policies',
      create: 'Add Backup Policy',
      edit: 'Edit Backup Policy',
      settings: 'Settings',
      schedule: 'Schedule',
      retentionDays: 'Retention',
      retentionDaysLong: 'Retention (days)',
      included: 'Included',
      notIncluded: 'Not Included',
      includedDisks: 'Disks',
      allDisks: 'All Disks in the Project',
      specificDisks: 'Specific Disks',
      toInclude: 'Include Disks',
      toExclude: 'Exclude Disks',
      allDisksIncluded:
        'All of the disks in the project are included. This includes disks that will be created in the future.',
    },
    network: {
      title: 'Network',
      titlePlural: 'Networks',
      create: 'Add Network',
      edit: 'Edit Network',
      noneAdded: 'No network has been added yet',

      vlanId: 'VLAN ID',
      vlanIdInfo: 'The VLAN ID has to be in the range of 1 to 4094.',

      ranges: {
        title: 'Range',
        titlePlural: 'Ranges',
        create: 'Add Range',
        edit: 'Edit Range',
        noneAdded: 'No Range has been added yet',

        subnet: 'Subnet',
        gateway: 'Gateway',
        rangeStart: 'Range start',
        rangeEnd: 'Range end',
      },
      routes: {
        title: 'Route',
        titlePlural: 'Routes',
        create: 'Add Route',
        edit: 'Edit Route',
        noneAdded: 'No Route has been added yet',

        destination: 'Destination',
        gateway: 'Gateway',
      },
    },
    networkServicePolicy: {
      title: 'Network Service Policy',
      titlePlural: 'Network Service Policies',
      info: 'Network Service Policies enable access to services running on your VM. Access can happen internally in the environment and/or externally from the internet.',
      services: {
        title: 'Service',
        titlePlural: 'Services',
        create: 'Add Service',
        edit: 'Edit Service',
        noneAdded: 'No Service has been added yet',

        internalName: 'Internal Name',
        externalDomainNames: {
          title: 'External Domain Name',
          titlePlural: 'External Domain Names',
          create: 'Add External Domain Name',
          edit: 'Edit External Domain Name',
          noneAdded: 'No External Domain Name has been added yet',
        },
        protocol: 'Protocol',
        protocolOptions: {
          http: 'HTTP',
          tcp: 'TCP',
        },
        sourcePort: 'Source Port',
        targetPort: 'Target Port',
        info: {
          internalName:
            'Name for the service that is used for identification within the environment. Other services in the project can use this name to access this service on the VM. Must be unique across a project.',
          externalDomainNames:
            'List of external domain names for public access. The service on the VM can be accessed from the internet using these domain names. The domain names must be registered and point to the public address of the VM. It can be retrieved after creation of the VM.',
          protocol:
            'Select the communication protocol. When HTTP is selected, TLS (HTTPS) will be set up automatically for public access. HTTP will be used internally.',
          sourcePort:
            'Specify the port under which the service should be accessible internally. The service can be reached internally under <internal-name>:<source-port>. E.g., a web server could be running on port 8080 on the VM but should be reached on the default HTTP port 80. In that case chose 8080 as target port and 80 as source port.',
          targetPort:
            'Specify the port under which the service is running on the VM. E.g., a web server could be running on port 8080 on the VM but should be reached on the default HTTP port 80. In that case chose 8080 as target port and 80 as source port.',
        },
      },
    },
  },
  pages: {
    login: {
      title: 'Login',
      loginMessage:
        'You are currently not signed in.\nPlease sign in with your FUNKE Cloud Service account.',
      loginButton: 'Sign in',
    },
    dashboard: {
      title: 'Dashboard',
      counts: {
        projects: 'Projects',
        clusters: 'Clusters',
        containers: 'Containers',
        virtualMachines: 'Virtual Machines',
        disks: 'Disks',
        networks: 'Networks',
        images: 'Images',
      },
      resources: 'Resources',
      capacity: {
        title: 'Capacity',
        cpuCores: 'CPU Cores',
        memoryGib: 'Memory (GiB)',
        storageGib: 'Storage (GiB)',
        storageIops: 'Storage IOPS',
      },
      overview: 'Overview',
    },
    projects: {
      title: 'Projects',
      noData: 'No projects available',
      popups: {
        delete: {
          title: (amount: number) =>
            `Are you sure, you want to delete ${amount === 1 ? 'this project' : 'these projects'}?`,
          toDelete: (amount: number) =>
            `The following project${amount !== 1 ? 's' : ''} will be deleted permanently:`,
          description: 'This action cannot be undone',
        },
      },
      notifications: {
        delete: {
          success: (amount: number, amountFailed?: number) =>
            `${amount} project${amount !== 1 ? 's' : ''} deleted` +
            (amountFailed != null && amountFailed > 0 ? `(${amountFailed} failed)` : ''),
          error: (amount: number) => `Error deleting ${amount} project${amount !== 1 ? 's' : ''}`,
          pending: (amount: number) => `Deleting ${amount} project${amount !== 1 ? 's' : ''}...`,
        },
        create: 'Project created',
        update: 'Project updated',
      },
    },
    projectQuotaRequests: {
      title: 'Quota Requests',
      pendingRequests: 'Pending Requests',
      answeredRequests: 'Answered Requests',
      accept: 'Accept',
      reject: 'Reject',
      accepted: 'Accepted',
      rejected: 'Rejected',
      pending: 'Pending',
      noData: 'No quota requests available',
      quota: 'Quota',
      currentValue: 'Current',
      newValue: 'New Value',
      status: 'Status',
      sender: 'User',
      maxCpuCores: 'CPU Cores',
      maxMemoryGib: 'Memory (GiB)',
      maxDiskSizeGib: 'Disk size (GiB)',
      maxDiskIops: 'Disk IOPS',
      maxVirtualMachineCount: 'Virtual Machines',
      maxClusterCount: 'Clusters',
      maxContainerCount: 'Containers',
      maxDiskCount: 'Disks',
      maxDiskCountPerVirtualMachine: 'Disks per Virtual Machine',
      maxNetworkCount: 'Networks',
      maxNetworkCountPerVirtualMachine: 'Networks per Virtual Machine',
      maxNetworkCountPerCluster: 'Networks per Cluster',
      maxNetworkCountPerContainer: 'Networks per Container',
      notifications: {
        accepted: 'Updated project quota',
        rejected: 'Rejected quota request',
      },
    },
    clusters: {
      title: 'Clusters',
      noData: 'No clusters available',
      buttons: {
        downloadKubeconfig: 'Download Kubeconfig',
      },
      popups: {
        delete: {
          title: (amount: number) =>
            `Are you sure, you want to delete ${amount === 1 ? 'this cluster' : 'these clusters'}?`,
          description: 'This action cannot be undone',
          toDelete: (amount: number) =>
            `The following cluster${amount === 1 ? '' : 's'} will be deleted permanently:`,
        },
      },
      notifications: {
        delete: {
          success: (amount: number, amountFailed?: number) =>
            `${amount} cluster${amount !== 1 ? 's' : ''} deleted` +
            (amountFailed != null && amountFailed > 0 ? `(${amountFailed} failed)` : ''),
          error: (amount: number) => `Error deleting ${amount} cluster${amount !== 1 ? 's' : ''}`,
          pending: (amount: number) => `Deleting ${amount} cluster${amount !== 1 ? 's' : ''}...`,
        },
        updateRunStatus: {
          success: (runStatus: 'running' | 'stopped') =>
            `Cluster ${runStatus === 'running' ? 'started' : 'stopped'}`,
          error: (runStatus: 'running' | 'stopped') =>
            `Error ${runStatus === 'running' ? 'starting' : 'stopping'} cluster`,
          pending: (runStatus: 'running' | 'stopped') =>
            `${runStatus === 'running' ? 'Starting' : 'Stopping'} cluster...`,
        },
        create: 'Cluster created',
        update: 'Cluster updated',
      },
    },
    installedApps: {
      title: 'Installed Apps',
      noData: 'No apps installed',

      popups: {
        uninstall: {
          title: (amount: number) =>
            `Are you sure, you want to uninstall ${amount === 1 ? 'this app' : 'these apps'}?`,
          description: 'This action cannot be undone',
          toDelete: (amount: number) =>
            `The following app${amount === 1 ? '' : 's'} will be uninstalled permanently:`,
        },
      },
      notifications: {
        uninstall: {
          success: (amount: number, amountFailed?: number) =>
            `${amount} app${amount !== 1 ? 's' : ''} uninstalled` +
            (amountFailed != null && amountFailed > 0 ? `(${amountFailed} failed)` : ''),
          error: (amount: number) => `Error uninstalling ${amount} app${amount !== 1 ? 's' : ''}`,
          pending: (amount: number) => `Uninstalling ${amount} app${amount !== 1 ? 's' : ''}...`,
        },
        create: 'App installed',
        update: 'App updated',
      },
    },
    appStore: {
      title: 'App Store',
      noData: 'No apps available',
    },
    secrets: {
      title: 'Secrets',
      noData: 'No secrets available',

      popups: {
        delete: {
          title: (amount: number) =>
            `Are you sure, you want to delete ${amount === 1 ? 'this secret' : 'these secrets'}?`,
          description: 'This action cannot be undone',
          toDelete: (amount: number) =>
            `The following secret${amount === 1 ? '' : 's'} will be deleted permanently:`,
        },
      },
      notifications: {
        delete: {
          success: (amount: number, amountFailed?: number) =>
            `${amount} secret${amount !== 1 ? 's' : ''} deleted` +
            (amountFailed != null && amountFailed > 0 ? `(${amountFailed} failed)` : ''),
          error: (amount: number) => `Error deleting ${amount} secret${amount !== 1 ? 's' : ''}`,
          pending: (amount: number) => `Deleting ${amount} secret${amount !== 1 ? 's' : ''}...`,
        },
        create: 'Secret created',
        update: 'Secret updated',
      },
    },
    configurations: {
      title: 'Configurations',
      noData: 'No configurations available',

      popups: {
        delete: {
          title: (amount: number) =>
            `Are you sure, you want to delete ${
              amount === 1 ? 'this configuration' : 'these configurations'
            }?`,
          description: 'This action cannot be undone',
          toDelete: (amount: number) =>
            `The following configuration${amount === 1 ? '' : 's'} will be deleted permanently:`,
        },
      },
      notifications: {
        delete: {
          success: (amount: number, amountFailed?: number) =>
            `${amount} configuration${amount !== 1 ? 's' : ''} deleted` +
            (amountFailed != null && amountFailed > 0 ? `(${amountFailed} failed)` : ''),
          error: (amount: number) =>
            `Error deleting ${amount} configuration${amount !== 1 ? 's' : ''}`,
          pending: (amount: number) =>
            `Deleting ${amount} configuration${amount !== 1 ? 's' : ''}...`,
        },
        create: 'Configuration created',
        update: 'Configuration updated',
      },
    },
    containers: {
      title: 'Containers',
      noData: 'No containers available',
      gpu: {
        enabled: 'Enabled',
        disabled: 'Disabled',
      },
      networking: 'Networking',
      popups: {
        delete: {
          title: (amount: number) =>
            `Are you sure, you want to delete ${
              amount === 1 ? 'this container' : 'these containers'
            }?`,
          description: 'This action cannot be undone',
          toDelete: (amount: number) =>
            `The following container${amount === 1 ? '' : 's'} will be deleted permanently:`,
        },
      },
      notifications: {
        delete: {
          success: (amount: number, amountFailed?: number) =>
            `${amount} container${amount !== 1 ? 's' : ''} deleted` +
            (amountFailed != null && amountFailed > 0 ? `(${amountFailed} failed)` : ''),
          error: (amount: number) => `Error deleting ${amount} container${amount !== 1 ? 's' : ''}`,
          pending: (amount: number) => `Deleting ${amount} container${amount !== 1 ? 's' : ''}...`,
        },
        updateRunStatus: {
          success: (runStatus: 'running' | 'stopped') =>
            `Container ${runStatus === 'running' ? 'started' : 'stopped'}`,
          error: (runStatus: 'running' | 'stopped') =>
            `Error ${runStatus === 'running' ? 'starting' : 'stopping'} container`,
          pending: (runStatus: 'running' | 'stopped') =>
            `${runStatus === 'running' ? 'Starting' : 'Stopping'} container...`,
        },
        create: 'Container created',
        update: 'Container updated',
      },
    },
    virtualMachines: {
      title: 'Virtual Machines',
      noData: 'No virtual machines available',
      details: {
        resources: 'Resources',
        vnc: {
          title: 'VNC Console',
        },
      },
      popups: {
        delete: {
          title: (amount: number) =>
            `Are you sure, you want to delete ${
              amount === 1 ? 'this virtual machine' : 'these virtual machines'
            }?`,
          toDelete: (amount: number) =>
            `The following virtual machine${amount !== 1 ? 's' : ''} will be deleted permanently:`,
          description: 'This action cannot be undone',
        },
      },
      notifications: {
        updateRunStrategy: {
          success: (runStrategy: 'always' | 'rerun-on-failure' | 'manual' | 'halted') =>
            `Virtual machine set to ${runStrategy === 'halted' ? 'halted' : 'running'}`,
          error: (runStrategy: 'always' | 'rerun-on-failure' | 'manual' | 'halted') =>
            `Error setting virtual machine to ${runStrategy === 'halted' ? 'halted' : 'running'}`,
          pending: (runStrategy: 'always' | 'rerun-on-failure' | 'manual' | 'halted') =>
            `Setting virtual machine to ${runStrategy === 'halted' ? 'halted' : 'running'}...`,
        },
        delete: {
          success: (amount: number, amountFailed?: number) =>
            `${amount} virtual machine${amount !== 1 ? 's' : ''} deleted` +
            (amountFailed != null && amountFailed > 0 ? `(${amountFailed} failed)` : ''),
          error: (amount: number) =>
            `Error deleting ${amount} virtual machine${amount !== 1 ? 's' : ''}`,
          pending: (amount: number) =>
            `Deleting ${amount} virtual machine${amount !== 1 ? 's' : ''}...`,
        },
        create: 'Virtual machine created',
        update: 'Virtual machine updated',
      },
    },
    disks: {
      title: 'Disks',
      noData: 'No Disks available',

      popups: {
        delete: {
          title: (amount: number) =>
            `Are you sure, you want to delete ${amount === 1 ? 'this disk' : 'these disks'}?`,
          description: 'This action cannot be undone',
          toDelete: (amount: number) =>
            `The following disk${amount === 1 ? '' : 's'} will be deleted permanently:`,
          notToDelete: (amount: number) =>
            `The following disk${amount === 1 ? '' : 's'} will not be deleted, because ${
              amount === 1 ? 'it is' : 'they are'
            } still in use:`,
        },
        restore: {
          title: 'Restore disk from snapshot',
          description: 'The disk will be overwritten with the following snapshot:',
          warn: 'This action causes data loss.',
        },
      },
      notifications: {
        restore: {
          success: 'Restored disk',
          error: 'Error restoring disk from snapshot',
          pending: 'Restoring disk...',
        },
        delete: {
          success: (amount: number, amountFailed?: number) =>
            `${amount} disk${amount !== 1 ? 's' : ''} deleted` +
            (amountFailed != null && amountFailed > 0 ? `(${amountFailed} failed)` : ''),
          error: (amount: number) => `Error deleting ${amount} disk${amount !== 1 ? 's' : ''}`,
          pending: (amount: number) => `Deleting ${amount} disk${amount !== 1 ? 's' : ''}...`,
        },
        create: 'Disk created',
        update: 'Disk updated',
      },
    },
    diskSnapshots: {
      title: 'Snapshots',
      noData: 'No snapshots available',
      popups: {
        delete: {
          title: (amount: number) =>
            `Are you sure, you want to delete ${
              amount === 1 ? 'this snapshot' : 'these snapshots'
            }?`,
          toDelete: (amount: number) =>
            `The following snapshot${amount === 1 ? '' : 's'} will be deleted permanently:`,
          description: 'This action cannot be undone',
        },
      },
      notifications: {
        delete: {
          success: (amount: number, amountFailed?: number) =>
            `${amount} snapshot${amount !== 1 ? 's' : ''} deleted` +
            (amountFailed != null && amountFailed > 0 ? `(${amountFailed} failed)` : ''),
          error: (amount: number) => `Error deleting ${amount} snapshot${amount !== 1 ? 's' : ''}`,
          pending: (amount: number) => `Deleting ${amount} snapshot${amount !== 1 ? 's' : ''}...`,
        },
        create: 'Snapshot created',
        update: 'Snapshot updated',
      },
    },
    images: {
      title: 'Images',
      noData: 'No images available',
      type: 'Type',
      typeOs: 'Operating System',
      typeOsShort: 'OS',
      typeData: 'Data',
      popups: {
        delete: {
          title: (amount: number) =>
            `Are you sure, you want to delete ${amount === 1 ? 'this image' : 'these images'}?`,
          description: 'This action cannot be undone',
          toDelete: (amount: number) =>
            `The following image${amount === 1 ? '' : 's'} will be deleted permanently:`,
          notToDelete: (amount: number) =>
            `The following image${amount === 1 ? '' : 's'} will not be deleted, because ${
              amount === 1 ? 'it is' : 'they are'
            } still in use:`,
        },
      },
      notifications: {
        delete: {
          success: (amount: number, amountFailed?: number) =>
            `${amount} image${amount !== 1 ? 's' : ''} deleted` +
            (amountFailed != null && amountFailed > 0 ? `(${amountFailed} failed)` : ''),
          error: (amount: number) => `Error deleting ${amount} image${amount !== 1 ? 's' : ''}`,
          pending: (amount: number) => `Deleting ${amount} data image${amount !== 1 ? 's' : ''}...`,
        },
        create: 'Image created',
        update: 'Image updated',
      },
    },
    backupPolicies: {
      title: 'Backup Policies',
      noData: 'No backup policies available',
      popups: {
        delete: {
          title: (amount: number) =>
            `Are you sure, you want to delete ${
              amount === 1 ? 'this backup policy' : 'these backup policies'
            }?`,
          description: 'This action cannot be undone',
          toDelete: (amount: number) =>
            `The following backup polic${amount === 1 ? 'y' : 'ies'} will be deleted permanently:`,
          notToDelete: (amount: number) =>
            `The following backup polic${amount === 1 ? 'y' : 'ies'} will not be deleted because ${
              amount === 1 ? 'it is a' : 'they are'
            } default backup polic${amount === 1 ? 'y' : 'ies'}:`,
        },
      },
      notifications: {
        delete: {
          success: (amount: number, amountFailed?: number) =>
            `${amount} backup polic${amount !== 1 ? 'ies' : 'y'} deleted` +
            (amountFailed != null && amountFailed > 0 ? `(${amountFailed} failed)` : ''),
          error: (amount: number) =>
            `Error deleting ${amount} backup polic${amount !== 1 ? 'ies' : 'y'}`,
          pending: (amount: number) =>
            `Deleting ${amount} backup polic${amount !== 1 ? 'ies' : 'y'}...`,
        },
        create: 'Backup policy created',
        update: 'Backup policy updated',
      },
    },
    networks: {
      title: 'Networks',
      noData: 'No networks available',
      noRanges: 'No ranges available',
      noRoutes: 'No routes available',

      createPage: {
        title: 'Add Network',
      },
      popups: {
        delete: {
          title: (amount: number) =>
            `Are you sure, you want to delete ${amount === 1 ? 'this network' : 'these networks'}?`,
          toDelete: (amount: number) =>
            `The following network${amount !== 1 ? 's' : ''} will be deleted permanently:`,
          description: 'This action cannot be undone',
        },
      },
      notifications: {
        delete: {
          success: (amount: number, amountFailed?: number) =>
            `${amount} network${amount !== 1 ? 's' : ''} deleted` +
            (amountFailed != null && amountFailed > 0 ? `(${amountFailed} failed)` : ''),
          error: (amount: number) => `Error deleting ${amount} network${amount !== 1 ? 's' : ''}`,
          pending: (amount: number) => `Deleting ${amount} network${amount !== 1 ? 's' : ''}...`,
        },
        create: 'Network created',
        update: 'Network updated',
      },
    },
    networkServicePolicies: {
      title: 'Network Service Policy',
      noData: 'No network service policy available',

      createPage: {
        title: 'Add Network Service Policy',
      },
      popups: {
        delete: {
          title: (amount: number) =>
            `Are you sure, you want to delete ${
              amount === 1 ? 'this network service policy' : 'these network service policies'
            }?`,
          description: 'This action cannot be undone',
        },
      },
    },
  },
}

export default en

export type BaseTranslation = typeof en
export type MissingKeys = DeepPartial<BaseTranslation>
