'use client'

import { deepmerge as deepMerge } from 'deepmerge-ts'
import { useParams } from 'next/navigation'
import { useMemo } from 'react'

import de from './de'
import { type BaseTranslation, default as en } from './en'

const locales = {
  en: en,
  de: de,
}

export const AVAILABLE_LOCALES = Object.keys(locales) as (keyof typeof locales)[]
export const BASE_LOCALE = 'en'

export const getLocaleFromUrl = (url: string): keyof typeof locales => {
  const locale = url.split('/')[1] as keyof typeof locales

  if (locale in locales) {
    return locale
  }

  return BASE_LOCALE
}

const loadLocale = (locale: keyof typeof locales): BaseTranslation => {
  if (locale === BASE_LOCALE) {
    return locales[BASE_LOCALE]
  }

  if (!(locale in locales)) {
    throw new Error(`Locale "${locale}" was not found. The url path entered was probably wrong.`)
  }

  return deepMerge(locales[BASE_LOCALE], locales[locale])
}

export const useTranslation = () => {
  const params = useParams()
  const locale =
    typeof params?.locale === 'string' && params?.locale in locales
      ? (params?.locale as keyof typeof locales)
      : BASE_LOCALE
  const loadedLocale = useMemo(() => loadLocale(locale), [locale])

  return {
    t: loadedLocale,
    locale,
  }
}
